// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAjdJnx7KSSHZxP369O9VPd2yVe115c52w",
  authDomain: "todo-243e1.firebaseapp.com",
  projectId: "todo-243e1",
  storageBucket: "todo-243e1.appspot.com",
  messagingSenderId: "870538934206",
  appId: "1:870538934206:web:2bef5e6e7da5711df56bef"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);

// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);

export default app;