import React from "react";
import { Navigate, Outlet } from 'react-router-dom'
import { useUserAuth } from "../context/userAuthContext";

const PrivateRoutes = () => {
  const { user } = useUserAuth();
  
  return (
    user ? <Outlet/> : <Navigate to='/'/>
  )
}

export default PrivateRoutes;